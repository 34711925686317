import React, { FC } from 'react';
import '../styles/App.scss';

interface ButtonProps {
    title: string;
    onClick?: () => void;
}

const Button: FC<ButtonProps> = (
    {
        title,
        onClick,
    }) => {
    return (
        <div className='btn_block' onClick={onClick}>
            <a className='btn to-form'>{title}</a>
        </div>
    );
};

export default Button;
