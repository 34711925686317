import React, { useRef } from 'react';
import Header from './Header';
import Button from './Button';
import Form from './Form';
import Footer from './Footer';

import '../styles/App.scss';
import abstraction from '../assets/icons/abstraction.svg';
import research from '../assets/images/research.png';
import contract from '../assets/images/contract.png';
import database_1 from '../assets/images/database_1.png';
import hosting from '../assets/images/hosting.png';
import launch from '../assets/images/launch.png';
import hotline from '../assets/images/hotline.png';
import Accordion from './Accordion';
import scalability from '../assets/images/scalability.png';
import interface_1 from '../assets/images/interface.png';
import rocket from '../assets/images/rocket-launch.png';
import time from '../assets/images/time-sheet.png';
import payment from '../assets/images/secure-payment.png';
import analytics from '../assets/images/analytics.png';


const FrontPage = () => {

    const myRef = useRef<HTMLDivElement>(null);

    const scrollToRef = (ref: any) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    };

    const executeScroll = () => {
        scrollToRef(myRef);
    };

    return (
        <div>
            <section className='main_sec'>
                <div className='main_sec-wrapper'>
                    <Header/>
                    <div className='container'>
                        <div className='cover_outside'>
                            <div className='left'>
                                <h1>One integration - all sources!</h1>
                                <p className='name'>AxiLink</p>
                                <span>Launch projects and comfortably interact with a large number of data providers</span>
                                <Button title='Get demo' onClick={executeScroll}/>
                            </div>
                            <div className='right'>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='how'>
                <div className='container'>
                    <h2> How <b>to find out everything</b> about a client?</h2>
                    <div className='cover_outside'>
                        <div className='step_block'>
                            <div className='img_block'><img src={research} alt='ico_1' /></div>
                            <div className='info_block'>1. Learn the strengths <br /> of your product</div>
                        </div>
                        <div className='step_block'>
                            <div className='img_block'><img src={contract} alt='ico_2' /></div>
                            <div className='info_block'>2. Agree on formats and<br /> sign an agreement</div>
                        </div>
                        <div className='step_block'>
                            <div className='img_block'><img src={database_1} alt='ico_3' /></div>
                            <div className='info_block'>3. Connect to the service</div>
                        </div>
                        <div className='step_block'>
                            <div className='img_block'><img src={hosting} alt='ico_4' /></div>
                            <div className='info_block'>4. Choose your data sources</div>
                        </div>
                        <div className='step_block'>
                            <div className='img_block'><img src={launch} alt='ico_5' /></div>
                            <div className='info_block'>5. Run the project</div>
                        </div>
                        <div className='step_block'>
                            <div className='img_block'><img src={hotline} alt='ico_6' /></div>
                            <div className='info_block'>6. Arrange support</div>
                        </div>
                    </div>
                    <p><b>All you need is to take the first step. </b>We will then take care of everything else.</p>
                </div>
            </section>

            <section className='what_is'>
                <div className='container'>
                    <h2>What is <b>AxiLink</b></h2>
                    <p>AxiLink is not just a proprietary solution that provides a one-stop shop for interacting with data providers.
                        Our tool optimizes your IT resources costs and allows you to fully automate the underwriting and verification
                        processes in lending and insurance issuing.</p>
                    <p className='bold'>Anyone can process requests and analyze data - only AxiLink can do it quickly and efficiently.</p>
                    <Button title='Get demo' onClick={executeScroll}/>
                </div>
            </section>

            <section className='decision'>
                <div className='container'>
                    <p className='top'><b>No chaos. We organize</b> the process to fit your business needs</p>
                    <div className='cover_outside'>
                        <div className='info_block'>
                            <p><b>Single</b> request format</p>
                        </div>
                        <div className='info_block'>
                            <p><b>Ready integrations</b> with industrial analytical systems (FICO, Experian SAS, Drools)
                            </p>
                        </div>
                        <div className='info_block'>
                            <p><b>Support</b> of major protocols and interaction modes</p>
                        </div>
                        <div className='info_block'>
                            <p><b>Ability to visualize</b> data in your CRM system</p>
                        </div>
                    </div>
                    <h2> Who is <b>this solution for?</b></h2>
                    <p className='bott'>All business projects that need an affordable solution to acquire data from different sources on their customers.
                        Our tool can be used by clients varying from banks and microfinance organizations, to insurance companies and online platforms.</p>
                </div>
            </section>

            <section className='our_vision'>
                <div className='container'>
                    <h3>Our solution is your comprehensive development</h3>
                    <div className='info_text'>
                        <p className='title'>IT.</p>
                        <p> Free up resources and focus on your[ business. When working with a large number of products and data providers,
                            bringing in a simple and effective integration solution will optimize your business operations.
                            Our team is always ready to set up channels to integrate with information systems, conduct further testing, and prepare the project to launch.</p>
                    </div>
                    <div className='info_text'>
                        <p className='title'>Risk technologies.</p>
                        <p> Do you constantly fight against fraud and work with a large amount of data, dreaming of
                            automating the collection and processing processes? We know how to quickly solve the tasks. AxiLink performs all
                            automated checks as part of the processing of loan applications and helps to form an exhaustive portrait of the borrower.</p>
                    </div>
                    <div className='info_text'>
                        <p className='title'> Business.</p>
                        <p>Looking to start selling goods or services on your online platform with payments by instalments?
                            With AxiLink you can launch your project within a month.</p>
                    </div>
                </div>
            </section>

            <section className='why_us'>
                <div className='container'>
                    <h2><b>Why</b> AxiLink?</h2>
                    <div className='cover_outside'>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={scalability} alt='ico_7'/>
                            </div>
                            <div className='info_block'>
                                <p>Scalability</p>
                                <span>Processing more than 10 million requests monthly.</span>
                            </div>
                        </div>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={interface_1} alt='ico_10'/>
                            </div>
                            <div className='info_block'>
                                <p>Versatility</p>
                                <span>Interaction with any information systems and adaptability to any data formats.</span>
                            </div>
                        </div>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={rocket} alt='ico_8'/>
                            </div>
                            <div className='info_block'>
                                <p>Speed</p>
                                <span>On average, integration with any external data source through our services takes one week.</span>
                            </div>
                        </div>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={time} alt='ico_11'/>
                            </div>
                            <div className='info_block'>
                                <p>Saving</p>
                                <span>80% lower integration support costs, 30% lower caching costs, 20% faster request  processing.</span>
                            </div>
                        </div>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={payment} alt='ico_9' />
                            </div>
                            <div className='info_block'>
                                <p>Safety</p>
                                <span>All service are carried out on behalf of the Customer, we do not have access to personal or sensitive data.</span>
                            </div>
                        </div>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={analytics} alt='ico_12'/>
                            </div>
                            <div className='info_block'>
                                <p> Fine installation</p>
                                <span>Manage parallel and serial requests, timeouts and number of attempts.</span>
                            </div>
                        </div>
                    </div>
                    <p className='end_title'>As a result of the provided services, you will receive a full-fledged data warehouse and tools made ready to work with straight away.</p>
                </div>
            </section>

            <div ref={myRef}>
                <Form/>
            </div>
            <Footer />
        </div>
    );
};

export default FrontPage;
