import React from 'react';

import './styles/App.scss';
import 'react-toastify/dist/ReactToastify.min.css';

import FrontPage from './components/FrontPage';
import { ToastContainer } from 'react-toastify';

function App() {
    return (
        <div className='wrapper'>
            <FrontPage/>
            <ToastContainer
                position='top-center'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover/>
        </div>
    );
}

export default App;
