import React, { ChangeEvent, useState } from 'react';
import { IFeedback } from '../shared/models/feedback';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { sendFeedback } from '../shared/feedback-api';

const Form = () => {
    let isBot = false;
    const [isLoading, setLoading] = useState(false);

    const schema = yup.object().shape({
        email: yup.string()
            .required('Required field')
            .email(),
        name: yup.string()
            .required('Required field')
            .min(2, 'Minimum length is 2')
            .max(256, 'Exceeded the maximum number of characters'),
        phoneNumber: yup.string().required('Required field')
            .min(4, 'Minimum length is 4')
            .max(30, 'Exceeded the maximum number of characters'),
        message: yup.string()
            .required('Required field')
            .min(2, 'The minimum length is 2'),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<IFeedback>({
        resolver: yupResolver(schema),
    });

    const onSubmit = (feedback: IFeedback) => {
        if (isBot) {
            return;
        }
        setLoading(true);
        sendFeedback(feedback)
            .then(
                (data) => {
                    if (data.data.status === 'SUCCESS') {
                        reset();
                        toast.success(<div>Your request has been successfully sent.<br/>
                            We will contact you soon!</div>);
                    }
                },
                (error) => {
                    console.log(error);
                    toast.error(<div>Sorry, something went wrong.<br/>
                        Try again, or contact later</div>);
                },
            )
            .finally(() => setLoading(false));
    };

    const catchBot = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            isBot = true;
        }
    };

    return (
        <section className='form' onSubmit={handleSubmit(onSubmit)}>
            <div className='container'>
                <div className='cover_outside'>
                    <div className='text_block'>
                        <p>Thanks to AxiLink, <b>knowing everything about your customers is easy</b>.
                            Automate evaluation and engagement processes - grow your business at an accelerated pace!</p>
                    </div>
                    <div className='form_block'>
                        <form action='/'>
                            <ul>
                                <li>
                                    <input type='text' name='theme' className='form-theme'
                                        placeholder='theme' onChange={(e) => catchBot(e)}/>
                                </li>
                                <li>
                                    <label htmlFor=''>Full Name</label>
                                    <input  type='text' placeholder='Your name' required
                                        disabled={isLoading} {...register('name')} />
                                    <span className='form__text form__text_error-message'>{errors.name?.message}</span>
                                </li>
                                <li>
                                    <label htmlFor=''>Email</label>
                                    <input type='email' placeholder='mail@example.com' required
                                        disabled={isLoading} {...register('email')} />
                                    <span className='form__text form__text_error-message'>{errors.email?.message}</span>
                                </li>
                                <li>
                                    <label htmlFor=''>Phone number</label>
                                    <input type='text' required
                                        disabled={isLoading} {...register('phoneNumber')} />
                                    <span className='form__text form__text_error-message'>{errors.phoneNumber?.message}</span>
                                </li>
                                <li>
                                    <label htmlFor=''>Message</label>
                                    <textarea required disabled={isLoading} {...register('message')} />
                                    <span className='form__text form__text_error-message'>{errors.message?.message}</span>
                                </li>
                                <li>
                                    <input type='submit' value='SEND' disabled={isLoading}/>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Form;
