import React from 'react';
import logo from '../assets/icons/logo.svg';
import fb from'../assets/icons/fb.svg';
import twitter from '../assets/icons/twitter.svg';
import youtube from '../assets/icons/youtube.svg';
import insta from '../assets/icons/insta.svg';

const Footer = () => {
    return (
        <footer>
            <div className='container'>
                <div className='cover_outside'>
                    <div className='left_col'>
                        <ul className='social'>
                            <li><a href=''><img src={fb} alt='social_1' /></a></li>
                            <li><a href=''><img src={twitter} alt='social_2' /></a></li>
                            <li><a href=''><img src={youtube} alt='social_3' /></a></li>
                            <li><a href=''><img src={insta} alt='social_4' /></a></li>
                        </ul>
                        <div className='menu_block'>
                            <div className='register-data'>
                                <p>All rights reserved</p>
                                <p>Axiomatika</p>
                                <p>Registered Address:</p>
                                <p>4/7 Amiryan street</p>
                                <p>Yerevan</p>
                                <p>Armenia 0002</p>
                            </div>
                            <p className='copyright'>© 2022 Axiomatika</p>
                        </div>
                    </div>
                    <div className='menu_block'>
                        <ul>
                            <li>Product</li>
                            <li><a href='#'>Axiomatika</a></li>
                            <li><a href='#'>AxiLink</a></li>
                            <li><a href='#'>Tariffs</a></li>
                        </ul>
                    </div>
                    <div className='menu_block'>
                        <ul>
                            <li>Axiomatika</li>
                            <li><a href='#'>Company</a></li>
                            <li><a href='#'>Jobs</a></li>
                        </ul>
                    </div>
                    <div className='menu_block'>
                        <ul>
                            <li>Contacts</li>
                            <li><a href='#'>Integrations</a></li>
                            <li><a href='#'>For Developers</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
